<template>
  <div>
    <template v-if="teams.length > 0">
      <div class="d-flex w-full justify-content-end pt-1 pr-1"> 
        <b-button
          variant="primary"
          @click="createTeam()"
          style="margin-bottom: 2rem"
        >
          Create New Team
        </b-button>
      </div>
      <div class="list-teams-card">
        <TeamCard
          v-for="team in teams"
          :key="team.id"
          :team="team"
          @openUnassigned="openUnassigned"
          @refreshData="refreshData"
        />
      </div>
    </template>
    <template v-else>
      <div class="d-flex flex-column align-items-center my-3">
        <b-img :src="noTeam" fluid alt="Card image cap" />
        <b-button variant="primary" @click="createTeam()">
          Create New Team
        </b-button>
      </div>
    </template>
    <DragTeamModal
      v-if="showUnassigned && unassigned.participants.length !== 0"
      :unassigned="unassigned"
      :selectedTeam="teamSelected"
      @close="showUnassigned = false"
      @refreshData="refreshData"
    />
    <CreateTeamModal
      @refreshData="refreshData"
      v-if="showCreateTeam"
      @close="showCreateTeam = false"
    />
  </div>
</template>

<script>
import DragTeamModal from "@/views/paragon/views/settings/assign-teams/DragTeamModal.vue";
import CreateTeamModal from "@/views/paragon/views/settings/assign-teams/CreateTeamModal.vue";
import TeamCard from "@/views/paragon/views/settings/assign-teams/TeamCard.vue";
import ParagonSettingsService from "@/views/paragon/views/settings/service/paragon.service.js";
export default {
  components: {
    TeamCard,
    CreateTeamModal,
    DragTeamModal,
  },
  data() {
    return {
      noTeam: require("@/assets/images/paragon/team.png"),
      teams: [],
      unassigned: {},
      showUnassigned: false,
      teamSelected: {},
      showCreateTeam: false,
    };
  },
  async mounted() {
    await this.getAssignedTeams();
    await this.getUnassignedUsers();
  },
  methods: {
    async getAssignedTeams() {
      try {
        const { data } = await ParagonSettingsService.getAssignedTeams();
        this.teams = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getUnassignedUsers() {
      try {
        const { data } =
          await ParagonSettingsService.getParticipantsUnassignedTeams();
        this.unassigned = {
          participants: data,
          id: null,
          name: "Unassigned Members",
        };
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async refreshData() {
      await this.getAssignedTeams();
      if (this.showUnassigned) {
        this.getUnassignedUsers();
        this.teamSelected = this.teams.find(
          (t) => t.id === this.teamSelected.id
        );
      }
    },
    openUnassigned(team) {
      if (this.showUnassigned) {
        return;
      }
      this.teamSelected = team;
      this.getUnassignedUsers();
      this.showUnassigned = true;
    },
    createTeam() {
      this.showCreateTeam = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-teams-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5rem;
  padding: 3rem 2rem;
}

.list-unassigned {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
}
</style>