<template>
  <div class="card-assign" :class="{ dark: isDarkSkin }">
    <div class="title-assign" :class="team.id === null ? 'bg-unassigned' : ''">
      <h1 class="d-flex align-items-center gap-5">
        <feather-icon
          icon="CodeIcon"
          style="margin-bottom: 2px"
          size="16"
          v-if="team.type_id == 1"
        />
        <feather-icon
          icon="FigmaIcon"
          style="margin-bottom: 2px"
          size="16"
          v-if="team.type_id == 2"
        />
        <span v-if="!team.editing" v-b-tooltip.hover.top="team.name">
          {{ teamName }}
        </span>
        <b-form-input
          v-model="team.name"
          @blur="
            $set(team, 'editing', false);
            updateNameTeam(team.id, team.name, team.type_id);
          "
          @keyup.enter="
            $set(team, 'editing', false);
            updateNameTeam(team.id, team.name, team.type_id);
          "
          autofocus
          v-if="team.editing"
        />
        <feather-icon
          icon="EditIcon"
          class="cursor-pointer"
          style="margin-bottom: 2px"
          size="16"
          v-if="!inModal"
          @click="$set(team, 'editing', true)"
          variant="primary"
        />
      </h1>
      <div class="d-flex align-items-center gap-5">
        <feather-icon
          v-if="team.default == 1 && team.type_id == 2"
          class="text-warning"
          icon="StarIcon"
          fill="orange"
          size="15"
        />
        <feather-icon
          v-if="team.default == 0 && team.type_id == 2"
          class="text-warning cursor-pointer"
          icon="StarIcon"
          size="15"
          @click="changeDefaultTeam(team.id)"
        />
        <b-badge
          class="cursor-pointer text-black"
          variant="success"
          @click="openUnassigned()"
          v-if="team.id && !inModal && totalMembers(team) !== 0"
        >
          <feather-icon
            icon="PlusCircleIcon"
            style="margin-right: 1px"
            size="16"
            variant="primary"
          />
          Add Members
        </b-badge>
        <b-badge
          class="cursor-pointer"
          variant="danger"
          v-if="team.id && !inModal"
          @click="deleteTeam()"
        >
          X
        </b-badge>
      </div>
    </div>
    <div class="list-users">
      <draggable
        v-model="team.participants"
        :options="{
          group: 'team-group',
          draggable: '.assign',
        }"
        @change="onDragEndParticipants(team.id, $event)"
        style="height: 100%"
      >
        <template v-if="totalMembers(team) !== 0">
          <UserCard
            v-for="participant in team.participants"
            :key="participant.id"
            :participant="participant"
            @refreshData="refreshData"
            :inModal="inModal"
          />
        </template>
        <template v-else>
          <div class="wo-participants">
            <feather-icon icon="UsersIcon" size="24" variant="primary" />
            <h1 class="mt-2">No Participants</h1>
            <b-badge
              class="cursor-pointer text-black"
              variant="success"
              @click="openUnassigned()"
              v-if="team.id && !inModal"
            >
              <feather-icon
                icon="PlusCircleIcon"
                style="margin-right: 5px"
                v-if="inModal"
                size="16"
                variant="primary"
              />
              Add Members
            </b-badge>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserCard from "@/views/paragon/views/settings/assign-teams/UserCard.vue";
import draggable from "vuedraggable";
import ParagonSettingsService from "@/views/paragon/views/settings/service/paragon.service.js";
export default {
  components: {
    draggable,
    UserCard,
  },
  props: {
    team: {
      type: Object,
      default: () => {},
    },
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCreateTeam: false,
      itemMoved: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    teamName() {
      const { name } = this.team;
      return name?.length > 18
        ? name.substring(0, 18) + "..."
        : name || "New Team";
    },
  },
  methods: {
    onDragStart(teamId, event) {
      this.$emit("onDragStart", teamId, event);
    },
    onDragAdd(teamId, typeId, event) {
      this.$emit("onDragAdd", teamId, typeId, event);
    },
    totalMembers(team) {
      return team.participants?.length || 0;
    },
    openUnassigned() {
      this.$emit("openUnassigned", this.team);
    },
    closeUnassigned() {
      this.$emit(
        "closeUnassigned",
        this.teamSelected.id,
        this.teamSelected.assign
      );
    },
    async deleteTeam() {
      const response = await this.showConfirmSwal(
        "Are you sure to delete the team?",
        null
      );
      try {
        if (response.isConfirmed) {
          const params = {
            user_id: this.currentUser.user_id,
          };
          await ParagonSettingsService.deleteTeam(this.team.id, params);
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.refreshData();
      }
    },
    refreshData() {
      this.$emit("refreshData");
    },
    async onDragEndParticipants(teamId, { added }) {
      if (added && teamId) {
        const { type_id } = this.team;
        const { role_id, id } = added.element;
        if (role_id == 2) {
          this.showToast(
            "warning",
            "top-right",
            "Error",
            "XIcon",
            `Supervisor can't be assigned to this team`
          );

          this.refreshData();
          return;
        }
        const isDev = type_id == 1 && role_id == 35;
        const isDesigner = type_id == 2 && role_id == 30;
        if (!isDev && !isDesigner) {
          this.showToast(
            "warning",
            "top-right",
            "Error",
            "XIcon",
            `Only ${
              type_id == 1 ? "Developer" : "Designer"
            } can be assigned to this team`
          );

          this.refreshData();
          return;
        }
        const itemId = id;
        const params = {
          dev_id: itemId,
          pg_team_id: teamId,
        };
        this.updateMember(params);
      } else if (added && !teamId) {
        const { id, role_id } = added.element;
        if (role_id == 2) {
          this.showToast(
            "warning",
            "top-right",
            "Error",
            "XIcon",
            `Supervisor can't be assigned to this team`
          );

          this.refreshData();
          return;
        }
        const itemId = id;
        const params = {
          dev_id: itemId,
          pg_team_id: null,
        };
        this.updateMember(params);
      }
    },
    async updateMember(params) {
      try {
        this.addPreloader();
        await ParagonSettingsService.updateAssignTeam(params);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.refreshData();
        this.removePreloader();
      }
    },
    async updateNameTeam(id, name, type) {
      try {
        const params = {
          id: id,
          name: name,
          type,
          type,
        };
        await ParagonSettingsService.updateNameTeam(params);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async changeDefaultTeam(id){
      const response = await this.showConfirmSwal(
        "are you sure about switching to default design team?",
        null
      );
      try {
        if (response.isConfirmed) {
          const params = {
            id: id
          };
          await ParagonSettingsService.changeDefaultTeam(params);
        }

      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.refreshData();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.card-assign {
  height: calc(40rem * var(--scale));
  width: calc(32rem * var(--scale));
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);

  .list-users {
    position: relative;
    height: calc(100% - 4rem);
    overflow-y: auto;
  }

  .title-assign {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    padding: 0 1rem;
    background-color: #f5f5f5;
    border-radius: 4px 4px 0 0;
    h1 {
      font-size: calc(1.4rem * var(--scale));
      font-weight: 600;
      color: var(--text-color);
      margin-bottom: 1px;
    }
  }

  .wo-participants {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    h1 {
      font-size: calc(1.4rem * var(--scale));
      font-weight: 600;
      color: var(--text-color);
      margin-bottom: 1px;
    }
  }
}

.bg-unassigned {
  background-color: #cce8ff !important;
}

.card-assign.dark {
  background-color: #2d3748;
  .title-assign {
    background-color: #1a202c;
  }
  .bg-unassigned {
    background-color: #0c1e41 !important;
  }
}
</style>