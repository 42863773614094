<template>
  <div class="card-user-assign" :class="{ dark: isDarkSkin , assign: participant.is_supervisor != 1 }">
    <div class="user-photo">
      <b-avatar 
        :src="participant.user_photo"
        size="3.5rem"
      >
      </b-avatar>
    </div>
    <div class="user-info">
      <h2>{{ participant.name | myFontCapitalize }}</h2>
      <h3>{{ participantName }}</h3>
    </div>
    <div class="user-actions" v-if="participant.role_id != 2 && !inModal">
      <div class="user-action-rounded" @click="removeUser">
        <feather-icon
          icon="XIcon"
          class="cursor-pointer text-danger"
          size="16"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ParagonSettingsService from "@/views/paragon/views/settings/service/paragon.service.js";
export default {
  props: {
    participant: {
      type: Object,
      default: () => {},
    },
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    participantName() {
      const { name_role, role_id } = this.participant;
      if (role_id == 2) {
        return "Supervisor";
      } else if (role_id == 35) {
        return "Developer";
      } else if (role_id == 30) {
        return "Designer";
      } else {
        return name_role;
      }
    },
  },
  methods: {
    async removeUser() {
      const response = await this.showConfirmSwal(
        `Are you sure you want to remove ${this.participant.name} from this team?`,
        null
      );
      if (!response.isConfirmed) return;
      const params = {
        dev_id: this.participant.id,
        pg_team_id: null,
      };
      this.updateMember(params);
    },
    async updateMember(params) {
      try {
        this.addPreloader();
        await ParagonSettingsService.updateAssignTeam(params);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.refreshData();
        this.removePreloader();
      }
    },
    refreshData() {
      this.$emit("refreshData");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-user-assign {
  display: flex;
  align-items: center;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding: 5px;

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 1rem;
    h2 {
      font-size: calc(1.4rem * var(--scale));
      font-weight: 600;
      margin-bottom: 0;
    }
    h3 {
      font-size: calc(1.2rem * var(--scale));
      font-weight: 400;
      margin-bottom: 0;
    }
  }
  .user-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    gap: 1rem;
  }
  .user-action-rounded {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #f5dddd8f;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-user-assign.dark {
  border-top: 1px solid #1a202c;
  border-bottom: 1px solid #1a202c;


  .user-info {
    h2 {
      color: #fff;
    }
    h3 {
      color: #fff;
    }
  }
  .user-action-rounded {
    background-color: #121822;
  }
}
</style>