<template>
  <b-modal
    ref="dragTeams"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    title="ASSIGN MEMBERS"
    size="smd"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <div class="d-flex justify-content-between gap-10">
      <TeamCard :team="selectedTeam" inModal @refreshData="refreshData" />
      <TeamCard :team="unassignedMembers" inModal @refreshData="refreshData" />
    </div>
  </b-modal>
</template>
<script>
import TeamCard from "@/views/paragon/views/settings/assign-teams/TeamCard.vue";
export default {
  components: {
    TeamCard,
  },
  props: {
    unassigned: {
      type: Object,
      default: () => {},
    },
    selectedTeam: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.toggleModal("dragTeams");
  },
  computed: {
    unassignedMembers() {
      let participants = [];
      if (this.selectedTeam.type_id === 1) {
        participants = this.unassigned.participants.filter(
          (participant) => participant.role_id === 35
        );
      } else {
        participants = this.unassigned.participants.filter(
          (participant) => participant.role_id === 30
        );
      }
      return {
        ...this.unassigned,
        participants,
      };
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    refreshData() {
      this.$emit("refreshData");
    },
  },
};
</script>