<template>
  <b-modal
    ref="modalTeamCreate"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    title="SELECT TEAM TYPE"
    size="lg"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <div class="d-flex justify-content-center" style="gap: 5rem">
      <div
        class="card-item"
        :class="typeSupervisor === 1 ? 'selected' : ''"
        @click="getOwners(1)"
      >
        <Developer style="height: 80%" />
        <h1>Development</h1>
      </div>
      <div
        class="card-item"
        :class="typeSupervisor === 2 ? 'selected' : ''"
        @click="getOwners(2)"
      >
        <b-img :src="designer" fluid alt="Card image cap" style="height: 80%" />

        <h1>Designer</h1>
      </div>
    </div>
    <div class="mt-1 px-2">
      <span class="font-weight-bolder text-dark">
        {{
          "SELECT " +
          (typeSupervisor === 1 ? "DEVELOPMENT" : "DESIGNER") +
          " SUPERVISOR"
        }}
      </span>
      <b-form-select v-model="supervisor" :options="supervisors" />
    </div>
    <template #modal-footer>
      <b-button class="rounded" @click="closeModal()"> Cancel </b-button>
      <b-button
        variant="primary"
        class="rounded"
        @click="addTeam(typeSupervisor)"
        :disabled="!supervisor"
      >
        Add Team
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import ParagonSettingsService from "@/views/paragon/views/settings/service/paragon.service.js";
import Developer from "@/views/paragon/views/settings/assign-teams/svg/Developer.vue";
export default {
  components: {
    Developer,
  },
  data() {
    return {
      designer: require("@/assets/images/paragon/designer.png"),
      typeSupervisor: 1,
      supervisors: [],
      supervisor: null,
    };
  },
  mounted() {
    this.toggleModal("modalTeamCreate");
    this.getOwners(1);
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async addTeam(type) {
      const response = await this.showConfirmSwal(
        `Are you sure you want to add a new ${
          type === 1 ? "Development" : "Designer"
        } Team?`,
        null
      );
      if (!response.isConfirmed) return;
      try {
        const params = {
          name: "New Group",
          type: type,
          supervisor: this.supervisor,
        };
        await ParagonSettingsService.addTeam(params);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.refreshData();
      }
    },
    async getOwners(typeSupervisor) {
      this.typeSupervisor = typeSupervisor;
      const response = await ParagonSettingsService.getOwnersByType(
        this.typeSupervisor
      );
      this.supervisors = response.map((owner) => {
        return {
          text: owner.name,
          value: owner.id,
        };
      });
      this.supervisors.unshift({
        value: null,
        text: "Please select a user",
      });
    },
    refreshData() {
      this.$emit("refreshData");
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-item {
  width: calc(20rem * var(--scale));
  height: calc(20rem * var(--scale));
  border-radius: 5px;
  border-style: dashed;
  border: 1px solid #a8a8a8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  &:hover {
    background-color: #c3daf3;
    border: 2px solid #007bff;
  }
  &.selected {
    background-color: #c3daf3;
    border: 2px solid #007bff;
  }
  h1 {
    font-size: calc(1.2rem * var(--scale));
    font-weight: 600;
    margin-bottom: 0;
  }
}
</style>
  